.carousel-root {
    width: 100%;
}
.carousel {
    width: 100%;
    .slider {
        width: 96px !important;
        gap: 10px
    }
    .slide {
        display: inline-flex;
    }
}

.donation {
    .carousel {
        .slider {
            width: 155px !important;
        }
    }
}