.modal {
    &.comment-modal {
        .modal-header {
            margin: 0rem 1rem;
            padding: 0.6rem 0;
        }
        .modal-title {
            font-size: 18px;
            font-weight: 600;
            color: #14191f;
        }
        .modal-body {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        .body-header {
            display: flex;
            justify-content: space-between;
            .user-info {
                display: flex;
                flex-direction: column;
                line-height: 18px;
                span {
                    &:first-child {
                        font-size: 16px;
                        font-weight: 600;
                        color: #14191f;
                    }
                    &:last-child {
                        font-size: 12px;
                        color: #878787;
                    }
                }
            }
            .date {
                font-size: 14px;
                color: #878787;
            }
        }
        .body-main {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            font-size: 16px;
            line-height: 21px;
            color: #14191f;
        }
        .body-footer {
            form {
                position: relative;
            }
        }

        > .close {
            cursor: pointer;
            position: absolute;
            display: block;
            padding: 2px 5px;
            line-height: 20px;
            right: -10px;
            top: -10px;
            font-size: 24px;
            background: #ffffff;
            border-radius: 18px;
            border: 1px solid #cfcece;
        }
    }
    &.donate-modal {
        .modal-header {
            border: 0;
            align-items: center;
        }
        .modal-title {
            display: flex;
            width: 100%;
            justify-content: center;
        }
        .btn-close {
            border-radius: 50%;
            font-size: 12px;
            padding: 13px;
            border: 1px solid #E0E0E0;
        }
    }
    .btn-close:focus {
        box-shadow: 0 0 0 0.1rem #46b04a81;
    }
}

.modal-320w {
    width: 320px;
}

.react-confirm-alert {
    background: #ffffff;
    border-radius: 8px;
    .react-confirm-alert-body {
        font-family: "FuturaPT-Book";
        color: #14191f;
        width: 540px;
        font-size: 20px;
        h1 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 31px;
        }
    }
    .react-confirm-alert-button-group {
        button {
            font-size: inherit;
        }
    }
}
