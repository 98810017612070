header {
    border-bottom: 1px solid #f0f1f2;
    padding: 0 12px;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1000px;
    margin: auto;
    height: 96px;
}

nav ul {
    list-style-type: none;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    @media (max-width: 767.98px) {
        position: fixed;
        top: 96px;
        left: 0;
        height: 100%;
        width: 100%;
        background: #e5e5e5;
        z-index: 10;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: inherit;
        display: none;
        &.opened {
            display: flex;
        }
    }
}

nav li {
    @media (max-width: 767.98px) {
        padding: 10px 12px;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
    }
    a {
        @media (max-width: 767.98px) {
            color: #14191f;
        }
    }
}

.logo {
    font-family: "NKDuyMono";
    font-style: normal;
    font-weight: 450;
    font-size: 25px;
    line-height: 51px;
}

.mobile-nav {
    display: none;
    @media (max-width: 767.98px) {
        display: flex;
        align-items: center;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 80px;
        padding: 3px 10px;
        text-transform: uppercase;
        font-family: "FuturaPT-Medium";
        font-size: 14px;
        gap: 4px;
        &:hover{
            cursor: pointer;
        }
        &.opened {
            .burger {
                gap: 0;
                .line {
                    &:first-child {
                        transform: rotate(45deg);
                    }
                    &:last-child {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

.burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    transition: 0.1s;
    .line {
        width: 20px;
        height: 1px;
        background-color: #14191f;
    }
}

.userImage {
    background-image: url('../img/avatar.svg');
    background-size: cover;
    width: 56px;
    height: 56px;
}