.radioButtons,
.checkboxButtons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 20px;
}

.examples {
    flex-direction: column;
    align-items: flex-start;
    list-style-type: disc;
    gap: 10px;
    max-height: 350px;
    overflow: auto;
}

.deliveries {
    display: flex;
    gap: 10px;
}

.image-item {
    position: relative;
    .delete {
        width: 10px;
        position: absolute;
        top: -25px;
        right: -15px;
        &:hover {
            cursor: pointer;
        }
    }
}

.small-loader {
    position: absolute;
    bottom: 28px;
    right: 10px;
    svg {
        width: 20px;
        height: 20px;
    }
}
