.upload-media {
	.upload-space {
		border: 2px dashed #46B04A;
		border-radius: 4px;
		padding: 20px;
		text-align: center;
		margin-top: 20px;

	}
	.upload-button {
		color: #46B04A;
		&:hover {
			cursor: pointer;
			font-weight: 600;
		}
	}
	.image-items {
		display: flex;
		gap: 15px;
		flex-wrap: wrap;
		padding-top: 20px;
		max-height: 280px;
		overflow-y: auto;
	}
	.image-item {
		img {
			border-radius: 4px;
			width: 118px;
			height: 96px;
			object-fit: cover;
		}
	}
}
.upload-image-wrapper {
	width: 100%;
}
