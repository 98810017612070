.nav-tabs {
    display: flex;
    gap: 24px;
    border: 0;
    .nav-link {
        border: 0;
        background-color: transparent;
        color: #14191F;
        padding: 5px 0;
        &.active {
            color: #46B04A;
            border-bottom: 1px solid #46B04A;
            background-color: transparent;
        }
    }
    @media (max-width: 767.98px) {
        padding: 0 12px;
    }
}

.tab-content {
    @media (max-width: 767.98px) {
        margin-bottom: 100px;
    }
}