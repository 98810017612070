.upload-file-wrapper {
    width: 100%;
}

.drop-area {
    border: 2px dashed #46B04A;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    margin-top: 20px;
    max-width: inherit;
    width: 100%;

    svg {
        display: none;
    }

    .upload-button {
		color: #46B04A;
		&:hover {
			cursor: pointer;
			font-weight: 600;
		}
	}
}

.uploaded-file-block {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    img {
        width: max-content;
    }
}