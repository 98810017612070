.bg-image {
    background-image: url('../img/bg.webp');
    background-size: cover;
    @media (max-width: 767.98px) {
        background-image: none;
    }
}

.login-content {
    min-height: calc(100vh - 97px);
    .container {
        max-width: 500px;
    }
    .card {
        padding: 32px 80px;
        @media (max-width: 767.98px) {
            padding: 0 12px;
        }
    }
}

.farmer-donor-cards {
    display: flex;
    justify-content: center;
    gap: 10px;
    @media (max-width: 767.98px) {
        padding: 0 12px;
        flex-direction: column;
        align-items: center;
    }
}