.translate-from {
    width: 50%;
    border-right: 1px solid #EFEFF3;
    padding: 1rem 32px 1rem 1rem;
    .nav {
        border-bottom: 1px solid #EFEFF3;
    }
}

.translate-to {
    width: 50%;
    padding: 1rem 1rem 1rem 32px;
    .nav {
        border-bottom: 1px solid #EFEFF3;
    }
}

.translate-block {
    font-size: 16px;
    .form-group {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .desc {
            height: 100px;
        }
    }
}

.modetarion-tab {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    &.active {
        font-weight: 700;
        text-shadow: 1px 0px #158b00;
    }
    .comments-count {
        width: 10px;
        height: 9px;
        position: absolute;
        top: -5px;
        right: -10px;
        background-image: url('../../img/comment-green.svg');
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: center;

    }
}

.budget-inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0;
    text-align: end;
}