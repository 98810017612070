.map-container {
    .mapboxgl-popup {
        position: absolute;
        top: 0;
        right: 0;
        left: unset;
        transform: none !important;
        max-width: 480px !important;
        overflow: auto;
        height: 100%;
        z-index: 1;
        .mapboxgl-popup-content {
            background: #ffffff;
            padding: 32px 24px;
            width: 480px;
            height: 100%;
            overflow: auto;
        }
        .mapboxgl-popup-tip {
            display: none;
        }
        .mapboxgl-popup-close-button {
            font-size: 20px;
            color: #656565;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 50%;
            margin: 15px;
            padding: 6px 11px;
            transition: 0.3s;
            &:hover {
                border: 1px solid #656565;
            }
        }
        @media(max-width: 576px) {
            width: 100vw !important;
        }
    }
    .map-popup {
        flex-direction: column;
        font-family: "FuturaPT-Book";
        font-style: normal;
        font-weight: 400;
        gap: 10px;
        &.application-card {
            @media(max-width: 767.98px) {
                border-top: 0;
            }
        }
        .description {
            font-size: 18px;
            line-height: 140%;
        }
    }
}
