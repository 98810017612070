footer {
    border-top: 1px solid #F0F1F2;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    max-width: 1000px;
    margin: auto;
    bottom: 0;
    width: 100%;
    padding: 0 12px;
    @media (max-width: 767.98px) {
        display: none;
    }

    .policy {
        display: flex;
        gap: 10px;
    }
}