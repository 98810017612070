main {
    background-color: #f9f9fb;
    margin: auto;
    min-height: calc(100vh - 97px - 49px);
    padding: 0 12px;
    @media (max-width: 767.98px) {
        padding: 0;
    }
}

.container {
    max-width: 1000px;
    padding: 0;
    padding: 40px 0;
    &.full-page {
        height: calc(100vh - 146px);
    }
}

.empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-top: 80px;
    @media (max-width: 767.98px) {
        padding: 0 12px;
    }
}

.loader-center {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.26);
    z-index: 2;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}

.collapse-images-header {
    display: none;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    padding: 10px 12px;
    position: relative;
    font-family: "FuturaPT-Book";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #14191f;

    &[aria-expanded="false"] {
        &::after {
            content: url("../img/plus.svg");
            position: absolute;
            right: 12px;
            top: 6px;
            transition: filter 0.5s;
        }
        &:hover {
            &::after {
                filter: drop-shadow(0px 0px 4px #656565);
            }
        }
    }
    &[aria-expanded="true"] {
        &::after {
            content: url("../img/minus.svg");
            position: absolute;
            right: 12px;
            top: 6px;
            transition: filter 0.5s;
        }
        &:hover {
            &::after {
                filter: drop-shadow(0px 0px 4px #656565);
            }
        }
    }

    &:hover {
        cursor: pointer;
    }
    @media (max-width: 767.98px) {
        display: flex;
    }
}

.list-page-header {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767.98px) {
        padding: 0 12px;
    }
    .page-title {
        padding: 0;
    }
}

.media-padding {
    @media (max-width: 767.98px) {
        padding: 0 12px;
    }
}