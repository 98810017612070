.donation {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    .collapse-images {
        // position: absolute;
        // top: -110px;
        // left: 50%;
        // transform: translate(-50%, 0);
        // width: 70%;
        img {
            width: 150px !important;
            height: 100px;
        }
    }
    .location {
        max-width: 250px;
    }
    .grey-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        gap: 16px;
        background: #dfe1df;
        border-radius: 12px;
        @media (max-width: 767.98px) {
            flex-direction: column;
        }
    }
}

.donate-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    width: 300px;
    min-height: 350px;
    img {
        width: 125px;
        align-self: center;
        border-radius: 4px;
    }
    @media (max-width: 767.98px) {
        flex-direction: column;
        border: 1px solid #efeff3;
    }
}

.category-amount {
    div {
        display: flex;
        span {
            &:nth-child(1) {
                width: 70%;
            }
            &:nth-child(2),
            &:nth-child(3) {
                width: 15%;
                text-align: center;
            }
        }
    }
    @media (max-width: 767.98px) {
        padding: 0 12px;
        div {
            span {
                &:nth-child(1) {
                    width: 50%;
                }
                &:nth-child(2),
                &:nth-child(3) {
                    width: 25%;
                }
            }
        }
    }
}