.moderation-table {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 18px;
    overflow: auto;
    thead {
        background: #f9f9fb;
        border-radius: 0px 0px 3px 3px;
        padding: 0 32px;
        font-size: 14px;
        color: #878787;
        text-transform: uppercase;
        th {
            padding: 15px 32px;
            @media (max-width: 767.98px) {
                padding: 10px
            }
        }
    }
    tbody {
        color: #14191F;
        td {
            padding: 5px 32px;
            border-bottom: 1px solid #E0E0E0;
            @media (max-width: 767.98px) {
                padding: 10px
            }
        }
        tr {
            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}

.company-info-table {
    overflow: auto;
    thead {
        border-radius: 0px 0px 3px 3px;
        padding: 0 32px;
        font-size: 14px;
        color: #878787;
        text-transform: uppercase;
        th {
            padding: 15px 20px;
        }
    }
    tbody {
        color: #14191F;
        font-size: 26px;
        td {
            padding: 0px 20px 25px 20px;
        }
    }
}

.budget-table {
    .form-group {
        padding: 0;
    }
    .rc-table-tbody {
        tr {
            &:nth-child(1),
            &:nth-child(6),
            &:nth-child(11) {
                background: #46b04a12;
            }
        }

    }
}
