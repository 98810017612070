@import-normalize;
@import 'bootstrap/dist/css/bootstrap.css';
@import './assets/scss/fonts.scss';
@import './assets/scss/typography.scss';
@import './assets/scss/buttons.scss';
@import './assets/scss/scroll.scss';

body {
  margin: 0;
  font-family: 'FuturaPT-Book';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #14191F;
}
