.page-title {
    font-family: "FuturaPT-Book";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #14191f;
}
@media (max-width: 767.98px) {
    .page-title {
        padding: 0 12px 12px 12px;
    }
}

.text {
    font-family: "FuturaPT-Book";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #14191f;
}

.text-bigger {
    font-family: "FuturaPT-Book";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #14191f;
}

.grey {
    color: #878787;
}

a {
    font-family: "FuturaPT-Book";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    color: #46b04a;
}

a.card-hover:hover {
    text-decoration: none;
    color: #000000;
}

a.card-hover:hover .donate-card{
    box-shadow: 0px 0px 1px 2px #0BCF13;
}

.circle {
    width: 20px;
    height: 20px;
    text-align: center;
    background: #656565;
    color: #ffffff;
    border-radius: 50%;
    font-size: 12px;
}

.link-gap {
    display: flex;
    align-items: center;
    gap: 6px;
}

.line {
    background-color: #efeff3;
}
.line.horizontal {
    width: 100%;
    height: 1px;
}
.line.vertical {
    height: 100%;
    width: 1px;
}

.column-gap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.row-gap {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.grey-uppercase {
    font-family: "FuturaPT-Book";
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;

    color: #878787;
}

.warning {
    font-size: 32px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.warning.warning-red {
    font-size: 32px;
    font-weight: 400;
    color: #FF2002;
}

.warning.warning-green {
    font-size: 32px;
    font-weight: 400;
    color: #0BCF13;
}

.text-underline {
    text-decoration: underline;
}

.background-grey {
    background: #e1e1e1;
}

.cut-text {
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text-bold {
    font-weight: 600;
}

@media (max-width: 767.98px) {
    .d-none-md {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .px-md {
        padding: 0 12px;
    }
}
