.comment {
    display: flex;
    align-items: center;
    font-size: 16px;
    position: relative;
    background: transparent;
    border: 0;
    height: 32px;
    margin-left: 50px;
    &::before {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        right: calc(100% + 5px);
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 50%;
    }
    &::after {
        content: url("../img/comment.svg");
        position: absolute;
        right: calc(100% + 12px);
        top: 2px;
    }
    &:hover {
        cursor: pointer;
        color: #46b04a;
        &::before {
            background: #f9f9fb;
        }
    }
    &:focus-visible {
        outline: 0;
    }
    .green-circle {
        position: absolute;
        top: 5px;
        right: calc(100% + 9px);
        width: 10px;
        height: 10px;
        background: #46b04a;
        border: 1px solid #f9f9fb;
        border-radius: 50%;
        z-index: 1;
    }
}
