.map-container {
    height: 200px;
    &.full {
        position: relative;
        height: 90%;
    }
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-right {
    display: none;
}

