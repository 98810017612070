.btn {
    padding: 6px 24px;
    border-radius: 4px;
    font-family: "FuturaPT-Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
}

.btn.btn-success {
    background: #46b04a;
    border: 0;
}

.btn.btn-success.disabled {
    background: #e1e1e1;
    border-radius: 4px;
    color: #acacac;
}

.btn.btn-outline-success {
    border: 1px solid #efeff3;
    color: #46b04a;
    background-color: transparent;
}

.btn.btn-link {
    font-family: "FuturaPT-Book";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    text-decoration: none;
}

.btn.btn-link:hover {
    text-decoration: underline;
    color: #46b04a;
}

.delete,
.edit,
.view {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    justify-content: end;
    transition: 0.5s;
    font-size: 16px;
}

.delete::before {
    content: url("../img/delete.svg");
    height: 30px;
}

.delete:hover,
.edit:hover,
.view:hover {
    filter: contrast(0.7);
    color: #46b04a;
    text-decoration: underline;
    cursor: pointer;
}

.edit::before {
    content: url("../img/edit.svg");
    height: 30px;
}

.accept,
.reject {
    display: flex;
    gap: 5px;
    align-items: center;
}

.accept::before {
    content: url("../img/accept.svg");
    height: 20px;
}

.reject::before {
    content: url("../img/reject.svg");
    height: 23px;
}

.download {
    display: flex;
    gap: 5px;
    color: #46b04a;
    text-transform: none;
    font-size: 16px;
}

.download::before {
    content: url("../img/download.svg");
}

.comment-button {
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 3px;
    height: 100%;
}

.comment-button:hover::before {
    content: url("../img/send-active.svg");
}

.comment-button::before {
    content: url("../img/send.svg");
}

@media (max-width: 767.98px) {
    .create-btn-media {
        font-size: 0;
        position: relative;
        background-color: transparent !important;
        border-radius: 50%;
        height: 30px;
    }
    .create-btn-media::after {
        content: url("../img/plus.svg");
        position: absolute;
        right: 0px;
        top: 6px;
        transition: filter 0.5s;
    }
    .create-btn-media:hover::after {
        filter: drop-shadow(0px 0px 4px #656565);
    }

    .delete-btn-media {
        font-size: 0;
        position: relative;
        background-color: transparent !important;
        border-radius: 50%;
        border: 0 !important;
        height: 30px;
    }
    .delete-btn-media::after {
        content: url("../img/delete.svg");
        position: absolute;
        right: 0px;
        top: 0px;
        transition: filter 0.5s;
    }
    .delete-btn-media:hover::after {
        filter: drop-shadow(0px 0px 4px #656565);
    }

    .reject,
    .accept {
        font-size: 0;
        height: 32px;
    }
}
