::-webkit-scrollbar {
	width: 4px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: linear-gradient(180deg, #46b04a 38.75%, #f9f9fb 105.46%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: darken(green, 10);
}