.custom-dropdown {
    padding: 0;
    border: 0;
    font-size: 18px;
    &:hover {
        box-shadow: none;
        cursor: pointer;
        color: #000000;
    }
    @media (max-width: 767.98px)  {
        background: transparent;
    }
}

.custom-dropdown-menu {
    min-width: 281px;
    background: #ffffff;
    border: 1px solid #efeff3;
    border-radius: 8px;
}

.no-arrow {
    display: none;
}

.language-dropdown {
    border: 0;
    text-transform: uppercase;
    padding: 8px 30px 8px 10px;
    &:hover {
        cursor: pointer;
        box-shadow: none;
    }
    @media (max-width: 767.98px) {
        background: transparent;
        padding: 0;
    }
}

.language-dropdown-menu {
    border-color: #ccc3;
    @media (max-width: 767.98px) {
        margin-top: 11px;
     }
}

.language-arrow-closed,
.language-arrow-open {
    background-image: url('../img/arrow.svg');
    border: 0;
    background-size: cover;
    width: 8px;
    height: 5px;
    right: 12px;
    top: 17px;
    position: absolute;
    transition: 0.5s;
    @media (max-width: 767.98px) {
       top: 8px;
    }
}

.language-arrow-open {
    transform: rotate(180deg);
}

.ua-option,
.en-option{
    display: flex;
    align-items: center;
    gap: 5px;
    text-transform: uppercase;
    font-family: 'FuturaPT-Book';
    font-style: normal;
    font-size: 14px;
    font-weight: 400;

}

.en-option {
    &::before {
        content: url('../img/english.svg');
        height: 18px;
    }
}

.ua-option {
    &::before {
        content: url('../img/ukraine.svg');
        height: 18px;
    }
}

.logout {
    display: flex;
    &::before {
        content: url('../img/logout.svg');
        height: 18px;
    }
}

.Dropdown-option {
    &:empty {
        padding: 0;
    }
}

.application-dropdown {
    border: 0;
    outline: 0;
    background: transparent;
}

.application-dropdown-list {
    width: 300px;
    ul {
        display: flex;
        flex-direction: column;
        align-items: start;
        background-color: #ffffff;
        gap: 10px;
        padding: 8px;
        border: 1px solid #efeff3;
        border-radius: 4px;
        z-index: 1;
        height: auto;
        @media (max-width: 767.98px) {
            li {
                border: 0;
            }
        }
    }
}