@font-face {
    font-family: "FuturaPT-Book";
    src: url("../fonts/FuturaPT-Book.eot") format("eot"), 
         url("../fonts/FuturaPT-Book.ttf") format("ttf"),
         url("../fonts/FuturaPT-Book.woff") format("woff");
}

@font-face {
    font-family: "FuturaPT-Medium";
    src: url("../fonts/FuturaPT-Medium.eot") format("eot"),
         url("../fonts/FuturaPT-Medium.ttf") format("ttf"),
         url("../fonts/FuturaPT-Medium.woff") format("woff");
}

@font-face {
    font-family: "NKDuyMono";
    src: url("../fonts/NKDuyMono-Regular.otf") format("otf"),
         url("../fonts/NKDuyMono-Regular.ttf") format("ttf"),
         url("../fonts/NKDuyMono-Regular.woff2") format("woff2");
}